import React, {useState} from "react";
import * as styles from "./ProjectList.module.css";
import {projectData} from "../../data/projectData";
import ProjectItem from "../projectitem/ProjectItem";
import {Project} from "../projectitem/Project";

const _projectData = projectData.map(p => new Project(p));

export default function ProjectList() {
    const [lockHolder, setLockHolder] = useState(_projectData[0].title);

    const acquireLock = (title) => setLockHolder(title);

    return (
        <div className={styles.listWrapper}>
            {_projectData.map(data => (
                <ProjectItem key={data.title} item={data} acquireLock={acquireLock} currentLockHolder={lockHolder}/>
            ))}
        </div>
    )
}