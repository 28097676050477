export const projectData = [
    {
        title: 'CoachingEnglish Website',
        description: "A community-first website for an English center. Utilizing GatsbyJS & Firebase Functions, Storage & Database " +
            "for a fast, responsive & dynamic websites. \n\n" +
            "This project started out using NextJS as its core technology, but later on migrated to Gatsby due to Firebase's limitation on geographic " +
            "location of NextJS projects.",
        imgUrl: "/project-images/CoachingEnglish.jpg"
    },
    {
        title: 'CoachingEnglish CMS',
        description: "A full-feature CMS built using ReactJS and AntDesign UI Library.\n\n" +
            "This website enable admin to manage contents & users (upcoming) on the main website.",
        imgUrl: "/project-images/CoachingEnglishAdmin.jpg"
    },
    {
        title: 'My Website',
        description: "This website utilize GatsbyJS, Netlify & NetlifyCMS for a fast & cost-free serverless website, " +
            "while allowing future extendability via the use of NetlifyCMS's JAMstack.\n\n" +
            "This website serves as a testament for the future of Frontend.",
        imgUrl: "/project-images/MyWebsite.jpg"
    }
]