import React from "react";
import * as styles from "./ProjectItem.module.css";

/**@param {Project} item
 * @param {String} currentLockHolder
 * @param {Function} acquireLock*/
export default function ProjectItem({item, currentLockHolder, acquireLock}) {

    const getClassName = () => {
        return [
            styles.itemWrapper,
            currentLockHolder === item.title ? styles.itemWrapperFocused : ""
        ].join(" ");
    }

    return (
        <div className={getClassName()} onMouseEnter={() => acquireLock(item.title)} role="none">
            <img className={styles.itemImage} src={item.imgUrl} alt={item.title} height={350}/>
            <div className={styles.itemTitle}>{item.title}</div>
            <div className={styles.itemDesc}>{item.description}</div>
        </div>
    )
}