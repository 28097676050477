import * as React from "react";
import Meta from "../components/shared/Meta";
import Layout from "../components/shared/Layout";
import ProjectList from "../components/projectslist/ProjectList";

const Projects = () => {
    return (
        <Layout>
            <Meta title="My projects"/>
            <ProjectList/>
        </Layout>
    )
}

export default Projects;